import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const PieChartComponent = ({ timelineData, investment, formatCurrency }) => {
  const [pieChartYears, setPieChartYears] = useState(30);
  const [sliderValue, setSliderValue] = useState(30);

  const { finalReturn, profit, data, COLORS } = useMemo(() => {
    const finalReturn = timelineData[pieChartYears]?.returns || 0;
    const profit = finalReturn - investment;
    const data = [
      { name: 'Eingesetztes Kapital', value: investment },
      { name: 'Ertrag', value: profit }
    ];
    const COLORS = ['#0088FE', '#00C49F'];
    return { finalReturn, profit, data, COLORS };
  }, [timelineData, pieChartYears, investment]);

  const handleSliderChange = (event) => {
    setSliderValue(Number(event.target.value));
  };

  const handleSliderChangeCommitted = () => {
    setPieChartYears(sliderValue);
  };

  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold mb-4">Kapitalverteilung nach {pieChartYears} Jahren</h3>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => formatCurrency(value, false)} />
        </PieChart>
      </ResponsiveContainer>
      <div className="center-text-block">
        <p>Eingesetztes Kapital: {formatCurrency(investment, false)}</p>
        <p>Gesamtertrag nach {pieChartYears} Jahren: {formatCurrency(finalReturn, false)}</p>
        <p>Gewinn: {formatCurrency(profit, false)}</p>
        <p>Jahre für Tortendiagramm: {sliderValue}</p>
      </div>
      <div className="mt-4">
        <input
          type="range"
          min="3"
          max="30"
          value={sliderValue}
          onChange={handleSliderChange}
          onMouseUp={handleSliderChangeCommitted}
          onTouchEnd={handleSliderChangeCommitted}
          className="slider"
        />
      </div>
    </div>
  );
};

const InvestmentCalculator = () => {
  const [investment, setInvestment] = useState(45000);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timelineData, setTimelineData] = useState([]);
  const [useInstallments, setUseInstallments] = useState(false);

  const minInvestments = {
    1: 100000,
    2: 25000,
    3: 100000
  };

  const isOptionAvailable = (option) => {
    if (option === 1) return investment >= 10000;
    return investment >= minInvestments[option];
  };

  const calculateReturns = (option, year) => {
    let effectiveInvestment = investment;
    if (option === 1 && useInstallments) {
      effectiveInvestment = Math.min(investment + year * 12 * 1000, 100000);
    }

    switch(option) {
      case 1:
        return effectiveInvestment * Math.pow(1.08, year);
      case 2:
        if (year < 4) return 0;
        if (year < 9) return [3700, 7400, 11300, 15100, 16400][year - 4];
        return investment + 17000 * (year - 8);
      case 3:
        return effectiveInvestment * Math.pow(1.10, year);
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (selectedOption && investment > 0) {
      const newTimelineData = Array.from({ length: 31 }, (_, i) => ({
        year: i,
        returns: Math.round(calculateReturns(selectedOption, i))
      }));
      setTimelineData(newTimelineData);
    }
  }, [selectedOption, investment, useInstallments]);

  const formatCurrency = (value, useAbbreviation = true) => {
    if (useAbbreviation) {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(0) + 'k';
      }
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border">
          <p>Jahr: {label}</p>
          <p>Ertrag: {formatCurrency(payload[0].value, false)}</p>
        </div>
      );
    }
    return null;
  };

  const FormulaDisplay = () => {
    if (!selectedOption) return null;
    
    let formula = "";
    switch(selectedOption) {
      case 1:
        formula = "Ertrag = Investition * (1,08^Jahr)";
        break;
      case 2:
        formula = "Ertrag = Spezifische Werte für Jahre 4-8, dann Investition + 17.000€ jährlich kumulativ ab dem 9. Jahr";
        break;
      case 3:
        formula = "Ertrag = Investition * (1,10^Jahr)";
        break;
    }
    
    return (
      <div className="mt-4 p-4 bg-gray-100 rounded">
        <h4 className="font-bold">Berechnungsformel:</h4>
        <p>{formula}</p>
        {selectedOption === 1 && useInstallments && (
          <p className="mt-2">Bei Ratenzahlung: Investition wächst monatlich um 1.000€ bis 100.000€</p>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4 calculator-container">
      <h2 className="text-2xl font-bold mb-4">Investitionsrechner</h2>
      <div className="mb-4">
        <input
          type="range"
          min="10000"
          max="1000000"
          step="1000"
          value={investment}
          onChange={(e) => setInvestment(Number(e.target.value))}
          className="slider mb-2"
        />
        <input
          type="number"
          value={investment}
          onChange={(e) => setInvestment(Number(e.target.value))}
          className="mt-2 w-full p-2 border rounded number-input"
        />
        <p className="text-sm mt-1">Investitionsbetrag: {formatCurrency(investment, false)}</p>
      </div>
      <div className="options-container">
        {[1, 2, 3].map((option) => (
          <div 
            key={option} 
            className={`option ${selectedOption === option ? 'selected border-green-500 border-2' : ''} ${!isOptionAvailable(option) ? 'disabled' : ''}`}
            onClick={() => isOptionAvailable(option) && setSelectedOption(option)}
          >
            <h3 className="font-bold">Option {option}</h3>
            <p>{option === 1 ? 'Landkauf + Pacht' : option === 2 ? 'Landkauf + Bio-Ernten' : 'Bio Projekt Teilnahme'}</p>
            <p>Mindestinvestition: {formatCurrency(minInvestments[option], false)}</p>
            {option === 1 && <p className="text-sm">Ratenzahlung ab 10.000€ Anzahlung möglich</p>}
            {!isOptionAvailable(option) && (
              <p className="text-blue-500">
                Verfügbar ab {formatCurrency(option === 1 ? 10000 : minInvestments[option], false)}
              </p>
            )}
          </div>
        ))}
      </div>
      {selectedOption === 1 && investment >= 10000 && investment < 100000 && (
        <div className="mb-4 installment-option">
          <label>
            <input 
              type="checkbox" 
              checked={useInstallments} 
              onChange={(e) => setUseInstallments(e.target.checked)}
            />
            <span>Ratenzahlung nutzen (1000€/Monat bis 100.000€ erreicht)</span>
          </label>
        </div>
      )}
      {timelineData.length > 0 && (
        <>
          <div className="mt-8">
            <h3 className="text-xl font-bold mb-4">Entwicklung über 30 Jahre</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={timelineData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" label={{ value: 'Jahre', position: 'insideBottom', offset: -5 }} />
                <YAxis 
                  label={{ value: 'Ertrag', angle: -90, position: 'insideLeft' }}
                  tickFormatter={(value) => formatCurrency(value, true)}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Line type="monotone" dataKey="returns" stroke="#82ca9d" name="Ertrag" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <PieChartComponent 
            timelineData={timelineData}
            investment={investment}
            formatCurrency={formatCurrency}
          />
          <FormulaDisplay />
        </>
      )}
    </div>
  );
};

export default InvestmentCalculator;
